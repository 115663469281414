var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt0"},[_c('div',{staticStyle:{"background":"#865A18","padding-bottom":"40px"}},[_c('h5',{staticClass:"pt40 center",staticStyle:{"color":"#fff"}},[_vm._v("Collaborators")]),_c('h1',{staticClass:"center",staticStyle:{"color":"white"}},[_vm._v("Knowledge Society")]),_c('div',{staticClass:"mb20 pl20 mt20 centered box center",staticStyle:{"background":"#865A18"}},[_c('i',{staticClass:"search icon",staticStyle:{"color":"white"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"noborder knowledge_search",staticStyle:{"color":"white"},attrs:{"placeholder":"Search By Name"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}})]),_c('div',{staticClass:"mentors_parent"},[_c('div',{staticClass:"mx12 mentors mentor_list",attrs:{"id":_vm.ID}},_vm._l((_vm.activeMentors),function(m,ix){return _c('div',{key:ix,staticClass:"mentor_card pointer",on:{"click":function($event){return _vm.openModal('mentor-' + ix)}}},[_c('div',{staticClass:"image",staticStyle:{"width":"60px","height":"60px","whitespace":"nowrap","flex-shrink":"0"},style:({
              background: 'url(' + m.image.url + ') center / cover no-repeat'
            })}),_c('div',{staticClass:"myauto flex vertical",staticStyle:{"height":"fit-content"}},[_c('h6',{staticClass:"px20 fs14 mobile-fs12"},[_vm._v(_vm._s(m.name))]),_c('p',{staticClass:"pt0 px20 fs12 aaa"},[_vm._v(" "+_vm._s(m.numberOfCourses)+" "+_vm._s(m.numberOfCourses == 1 ? "Course / Lecture" : "Courses")+" ")])]),_c('div',{staticClass:"ui modal py20 px40",attrs:{"id":'mentor-' + ix}},[_c('h6',{staticClass:"pb20",staticStyle:{"border-bottom":"1px solid #eee"}},[_vm._v(" "+_vm._s(m.name)+" ")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"image mt20 ml0 mrauto br8 noshrink",staticStyle:{"width":"140px","height":"140px"},style:({
                  background:
                    'url(' + m.image.url + ') center / cover no-repeat'
                })}),_c('div',{staticClass:"ml20 mt20"},[_c('p',{staticClass:"mt20 bold mb8"},[_vm._v("About")]),_c('p',[_vm._v(_vm._s(m.about))])])])])])}),0)]),_c('div',{staticClass:"mt12 pb40 centered flex"},_vm._l((new Array(_vm.mentorPages).fill(1)),function(p,i){return _c('div',{key:i,staticClass:"w24 h24 br20 mx4 flex",staticStyle:{"color":"white","cursor":"pointer"},style:({
          background: _vm.activePage == i ? 'rgba(255, 255, 255, 0.3)' : ''
        }),on:{"click":function($event){_vm.activePage = i}}},[_c('span',{staticClass:"mauto"},[_vm._v(_vm._s(i + 1))])])}),0)]),_c('div',{staticStyle:{"padding-bottom":"40px"}},[_c('h5',{staticClass:"pt40 center"},[_vm._v("Collaborators")]),_c('h1',{staticClass:"center"},[_vm._v("Association Partners")]),_c('div',{staticClass:"partner-tabs flex"},[_c('h4',{staticClass:"partner-tab",class:{ active: _vm.associationState == 'current' },on:{"click":function($event){_vm.associationState = 'current'}}},[_vm._v(" Current Partners ")]),_c('h4',{staticClass:"partner-tab",class:{ active: _vm.associationState == 'past' },on:{"click":function($event){_vm.associationState = 'past'}}},[_vm._v(" Past Partners ")])]),_c('div',{staticClass:"flex wrap pt40"},_vm._l((_vm.$bus.siteData[_vm.associationKey]),function(l){return _c('div',{key:l.url,staticClass:"partner flex",staticStyle:{"height":"60px","margin-top":"20px"}},[_c('div',{staticClass:"ml40 mrauto",staticStyle:{"width":"100px","height":"100%"},style:({
            background: 'url(' + l.url + ') center / contain no-repeat'
          })})])}),0)]),_c('div',{staticClass:"pb40",staticStyle:{"background":"#eeeeee"}},[_c('h5',{staticClass:"pt40 center"},[_vm._v("Collaborators")]),_c('h1',{staticClass:"center"},[_vm._v("Corporate Partners")]),_c('div',[_c('div',{staticClass:"partner-tabs flex"},[_c('h4',{staticClass:"partner-tab",class:{ active: _vm.corporateState == 'current' },on:{"click":function($event){_vm.corporateState = 'current'}}},[_vm._v(" Current Partners ")]),_c('h4',{staticClass:"partner-tab",class:{ active: _vm.corporateState == 'past' },on:{"click":function($event){_vm.corporateState = 'past'}}},[_vm._v(" Past Partners ")])]),_c('div',{staticClass:"flex wrap"},_vm._l((_vm.$bus.siteData[_vm.corporateKey]),function(l){return _c('div',{key:l.url,staticClass:"partner flex",staticStyle:{"height":"60px","margin-top":"20px"}},[_c('div',{staticClass:"ml40 mrauto",staticStyle:{"width":"100px","height":"100%"},style:({
              background: 'url(' + l.url + ') center / contain no-repeat'
            })})])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }